<template>
  <hr>
</template>

<style lang="scss" scoped>
hr {
  background: url('/assets/img/hr.svg') no-repeat;
  background-size: contain;
  height: 5px;
  width: 211px;
  margin: 1rem 0;
  border: 0;
}
</style>
